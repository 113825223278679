// Swiper initialization and other functionalities
// import Alpine from 'alpinejs';
// Alpine.start();
document.addEventListener('DOMContentLoaded', () => {
   

    const defaultConfig = {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1299: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1499: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            1599: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
        },
    };

    const initSwiper = (selector, config) => new Swiper(selector, config);

    initSwiper(".mySwiper", defaultConfig);
    initSwiper(".mySwiper1", {
        ...defaultConfig,
        loop: false,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1299: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1499: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1599: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    initSwiper(".skeleton_mySwiper1", {
        ...defaultConfig,
        loop: false,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1299: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1499: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1599: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    initSwiper(".mySwiper2", {
        ...defaultConfig,
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
    });

    initSwiper(".mySwiper3", {
        ...defaultConfig,
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
        },
        lazy: {
            loadPrevNext: true,
        },
    });

    initSwiper('.clients_slide .swiper-container', {
        loop: true,
        speed: 500,
        autoplay: {
            delay: 8500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            414: {
                slidesPerView: 3,
                spaceBetween: 25,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 25,
            },
            991: {
                slidesPerView: 5,
                spaceBetween: 25,
            },
        },
    });

    // Open and close menu
    window.openmenu = () => {
        document.getElementById("close-menu").style.display = "block";
        document.getElementById("openmenu").style.display = "none";
    };

    window.closemenu = () => {
        document.getElementById("close-menu").style.display = "none";
        document.getElementById("openmenu").style.display = "block";
    };

    // Counter
    const counters = document.querySelectorAll('.counter');
    const speed = 200; // The lower the slower

    counters.forEach(counter => {
        const updateCount = () => {
            const target = +counter.getAttribute('data-target');
            const count = +counter.innerText;
            const inc = target / speed;
            if (count < target) {
                counter.innerText = count + inc;
                setTimeout(updateCount, 1);
            } else {
                counter.innerText = target;
            }
        };
        updateCount();
    });

    // Typing effect
    class TxtType {
        constructor(el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 3000;
            this.txt = '';
            this.isDeleting = false;
            this.tick();
        }

        tick() {
            const i = this.loopNum % this.toRotate.length;
            const fullTxt = this.toRotate[i];
            this.txt = this.isDeleting ? fullTxt.substring(0, this.txt.length - 1) : fullTxt.substring(0, this.txt.length + 1);
            this.el.innerHTML = `<span class="wrap">${this.txt}</span>`;
            let delta = 200 - Math.random() * 100;
            if (this.isDeleting) delta /= 2;
            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period;
                this.isDeleting = true;
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }
            setTimeout(() => this.tick(), delta);
        }
    }

    document.querySelectorAll('.typewrite').forEach(element => {
        const toRotate = element.getAttribute('data-type');
        const period = element.getAttribute('data-period');
        if (toRotate) new TxtType(element, JSON.parse(toRotate), period);
    });

    // Service we have section
    const ul = document.getElementById("mainList");
    const liList = ul.querySelectorAll("li");
    const imageList = document.querySelectorAll(".hiddenImage");

    ul.addEventListener("click", event => {
        const { id } = event.target;
        liList.forEach(e => e.classList.toggle("underlines", e.id === id));
        const sectionId = id.split("_")[1];
        imageList.forEach(e => e.classList.toggle("visibleImage", e.id === sectionId));
    });
});
